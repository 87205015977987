import type {
  EventDate,
  EventDateType,
  EventDescription,
} from '~/composables/types/event'

export const useEventData = () =>
  useState<EventDescription | undefined>('eventData', () => undefined)

export const useEventDatesStore = (
  id: Ref<string> | string,
  type: EventDateType
) => {
  const dates = useState<EventDate[]>(
    `eventDateData-${toValue(id)}-${type}`,
    () => []
  )

  const set = (newDates: EventDate[]) => {
    dates.value = newDates
  }

  return {
    dates,
    set,
  }
}
